import React, { Component } from 'react';
import { Outlet } from 'react-router-dom';
import StickyHeader from './StickyHeader';
import MobileMenu from './MobileMenu';
import Footer from './Footer';
import {  Spinner } from 'react-bootstrap';

class Layout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        this.setLoadingState();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.setLoadingState();
        }
    }

    setLoadingState = () => {
        this.setState({ loading: true });

        setTimeout(() => {
            this.setState({ loading: false });
        }, 1500);
    };
    render() {
        return (
            <>
                {this.state.loading ? (<>

                    <div className="page-loader d-flex justify-content-center align-items-center"> 
                        <Spinner animation="border" variant="primary" role="status" className="ms-3">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>

                </>) : (<>

                    <StickyHeader />
                    <MobileMenu />
                    <Outlet />
                    <Footer />

                </>)}
            </>
        );
    }
}


export default Layout;