export const API_ENDPOINT="https://adroxmarket.com";
export const APP_ENDPOINT="https://adroxmarket.com";

export const SOLUSDT_SOCKET = "wss://stream.binance.com:9443/ws/solusdt@trade";
export const USDTUSDT_SOCKET = "wss://stream.binance.com:9443/ws/usdtusdt@trade";
export const BNBUSDT_SOCKET = "wss://stream.binance.com:9443/ws/bnbusdt@trade";

export const SOLANA_ENDPOINT = "https://api.mainnet.solana.com" //for testing https://api.devnet.solana.com  for live https://api.mainnet-beta.solana.com
export const BINANCE_ENDPOINT= "https://api.binance.com";

// export const SOLANA_WALLET = "2giFeBYaYiJ2aa4ELHBneKz2NWjnFzpoWi7DJvtQnWtp"  // for test 3n29seQ7mLSaUNJbSpaMhgnEiR9JgFABiLvejZrDtE3U for live 2giFeBYaYiJ2aa4ELHBneKz2NWjnFzpoWi7DJvtQnWtp
// export const BINANCE_WALLET = "0x7af8779b6EdC08767CE11600F35289Ea75f17484"
export const BINANCE_WALLET_CONTRACT = "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"
// export const TETHER_WALLET = "0x7af8779b6EdC08767CE11600F35289Ea75f17484"
export const TETHER_WALLET_CONTRACT = "0x55d398326f99059fF775485246999027B3197955";
 

export const BINANCE_W3 = "https://bsc-dataseed.binance.org/"
export const SOLANA_W3 = "https://api.mainnet.solana.com/"