import React, { Component } from "react";
import { Button, Col, Container, Form, Row, Spinner, Tab, Table, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import Order from "../service/Order";
import ADXCoin from "../service/ADXCoin";

import l1 from "../assets/images/l1.png"


class Wallet extends Component {

    constructor(props) {
        super(props);
        this.state = {
            AccountOrder: [],
            loginDetails: Order.LoginedUser,
            orderstatus: "pending",
            formLoginData: {
                username: null,
                password: null,
            },
            formRegisterData: {
                username: null,
                password: null,
                cpassword: null,
            },
            loginError:{
                username: null,
                password: null,
            },
            registerError:{
                username: null,
                password: null,
                cpassword: null,
            },
            isSubmit: false,
            formKey: "login",
        }
    }
    componentDidMount() {
        this.intervalId = setInterval(this.handleInterval, 1000);
        this.getAccountOrders()
    }
    componentWillUnmount() {
        clearInterval(this.intervalId);
    }
    handleInterval = () => {
        const loginDetails = Order.LoginedUser;        
        const orderstatus = Order.Account.status||"pending";
        this.setState({ loginDetails,orderstatus })
    }
    getAccountOrders = async () => {
        const AccountOrder = await Order.getOrder()
        this.setState({ AccountOrder })
    }
    handleRegisterSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        let isSubmit = true;
        this.setState({ isSubmit })
        const formData = {
            ...Order.Account,
            ...this.state.formRegisterData
        }
        try {
            const attempt = await Order.registerAccount(formData);
            if(attempt.status){
                const formRegisterData = {                
                    username: null,
                    password: null,
                    cpassword: null,
                }
                Order.Account={
                    ...Order.Account,
                    ...attempt.auth
                }
                Order.LoginedUser={
                    ...attempt.auth
                }
                this.setState({ formRegisterData })
            }else{
                const registerError = {
                    ...this.state.registerError,
                    ...attempt.error
                }
                this.setState({ registerError })
            }
        } catch (error) {
            
        }
        isSubmit = false;
        this.setState({ isSubmit })
    }
    handleLoginSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        let isSubmit = true;
        this.setState({ isSubmit })
        const formData = {
            ...Order.Account,
            ...this.state.formLoginData
        }
        try {
            const attempt = await Order.loginAccount(formData);
            if(attempt.status){
                const formLoginData = {                
                    username: null,
                    password: null,
                }
                Order.Account={
                    ...Order.Account,
                    ...attempt.auth,
                    ...attempt.auth.account
                }
                ADXCoin.Account={
                    ...ADXCoin.Account,
                    ...attempt.auth.account
                }
                Order.LoginedUser={
                    ...attempt.auth
                }
                this.setState({ formLoginData })
            }else{
                const loginError = {
                    ...this.state.loginError,
                    ...attempt.error
                }
                this.setState({ loginError })
            }
        } catch (error) {
            
        }
        isSubmit = false;
        this.setState({ isSubmit })
    }
    handleFormKey = async (formKey) => {
        this.setState({ formKey })
    }
    handleLoginChange = async (e) => {
        const { name, value } = e.target;
        const formLoginData = {
            ...this.state.formLoginData,
            [name]: value
        }
        this.setState({ formLoginData })
    }
    handleRegisterChange = async (e) => {
        const { name, value } = e.target;
        const formRegisterData = {
            ...this.state.formRegisterData,
            [name]: value
        }
        this.setState({ formRegisterData })
    }
    render() {
        return <>
            <div class="sign-in-section pt-100 pb-100">
                <Container >
                    <Row>
                        {this.state.loginDetails && this.state.loginDetails.uid && (<Col lg={2}></Col>)}
                        <Col lg={8} sm={12}>
                            {this.state.orderstatus==="active"?(<div class="sign-in-respond text-center">
                                <div class="sign-in-title">
                                    <h2>My Wallet</h2>
                                    <p>1 ADX = 0.05$</p>
                                </div>
                                <div class="table-reponsive box">
                                    <Table striped bordered hover responsive className="table-dark">
                                        <thead>
                                            <tr>
                                                <th> #ID</th>
                                                <th>Volume</th>
                                                <th>ADX</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.AccountOrder&&[...this.state.AccountOrder].sort((a, b) => a.id - b.id).map((order) => {
                                                    return <tr class="single">
                                                        <td>#{order.orderid}</td>
                                                        <td>{order.amount} {order.coinname}</td>
                                                        <td>{order.adxcoin} ADX</td>
                                                        <td>{order.orderstatus === "paid" || order.orderstatus === "closed" ? "Complete" : order.orderstatus}</td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                                <div class="wallet-button">
                                    <Link onClick={()=>{}}>Withdraw </Link>
                                </div>
                            </div>):(<div class="sign-in-respond text-center">
                                <div class="sign-in-title">
                                    <img src={l1} alt="logo"/>
                                </div>
                            </div>)}
                        </Col>
                        {this.state.loginDetails && this.state.loginDetails.uid ? (<Col lg={2}></Col>) : (<Col lg={4} sm={12}>
                            <Tabs
                                id="controlled-tab-form"
                                activeKey={this.state.formKey}
                                onSelect={this.handleFormKey}
                                className="mb-3"
                            >
                                <Tab eventKey="login" title="Login" className="login-tab">
                                    <div class="sign-in-respond text-center">
                                        <div class="sign-in-title">
                                            <h2>Login</h2>
                                            <span>Log in to save your data and avoid losing it.</span>
                                        </div>
                                        <div class="sign-in-body">
                                            <Form noValidate onSubmit={this.handleLoginSubmit}>
                                                <Col lg={12}>
                                                    <div className="from-box">
                                                        <input type="text" placeholder="Username" value={this.state.formLoginData.username} name='username' onChange={this.handleLoginChange} />
                                                        {this.state.loginError.username&&(<small className="error text-danger">{this.state.loginError.username}</small>)}
                                                    </div>
                                                </Col>
                                                <Col lg={12}>
                                                    <div className="from-box">
                                                        <input type="password" placeholder="Password" value={this.state.formLoginData.password} name='password' onChange={this.handleLoginChange} />
                                                        {this.state.loginError.password&&(<small className="error text-danger">{this.state.loginError.password}</small>)}
                                                    </div>
                                                </Col>
                                                <Col lg={12}>
                                                    <div className="contact-items">
                                                        <Button type='submit' disabled={this.state.isSubmit} >Login {this.state.isSubmit && (<><Spinner animation="grow" /></>)}</Button>
                                                    </div>
                                                </Col>
                                            </Form>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="register" title="Register" className="register-tab">
                                    <div class="sign-in-respond text-center">
                                        <div class="sign-in-title">
                                            <h2>Register</h2>
                                            <span>Register an account to save your data and avoid losing it.</span>
                                        </div>
                                        <div class="sign-in-body">
                                            <Form noValidate onSubmit={this.handleRegisterSubmit}>
                                                <Col lg={12}>
                                                    <div className="from-box">
                                                        <input type="text" placeholder="Username" value={this.state.formRegisterData.username} name='username' onChange={this.handleRegisterChange} />
                                                        {this.state.registerError.username&&(<small className="error text-danger">{this.state.registerError.username}</small>)}
                                                    </div>
                                                </Col>
                                                <Col lg={12}>
                                                    <div className="from-box">
                                                        <input type="password" placeholder="Password" value={this.state.formRegisterData.password} name='password' onChange={this.handleRegisterChange} />
                                                        {this.state.registerError.password&&(<small className="error text-danger">{this.state.registerError.password}</small>)}
                                                    </div>
                                                </Col>
                                                <Col lg={12}>
                                                    <div className="from-box">
                                                        <input type="password" placeholder="Confirm Password" value={this.state.formRegisterData.cpassword} name='cpassword' onChange={this.handleRegisterChange} />
                                                        {this.state.registerError.cpassword&&(<small className="error text-danger">{this.state.registerError.cpassword}</small>)}
                                                    </div>
                                                </Col>
                                                <Col lg={12}>
                                                    <div className="contact-items">
                                                        <Button type='submit' disabled={this.state.isSubmit} >Register {this.state.isSubmit && (<><Spinner animation="grow" /></>)}</Button>
                                                    </div>
                                                </Col>
                                            </Form>
                                        </div>
                                        <div class="sign-in-note">
                                            <span><strong>Note:</strong> Creating an account ensures that your information is securely stored, and you won't lose your progress or data. Don't forget to log in before you start!</span>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </Col>)}
                    </Row>
                </Container>
            </div>
        </>
    }
}
export default Wallet;