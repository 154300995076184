import React, { Component } from 'react';
import Animation, { fadeInDown, fadeInLeft, fadeInUp } from '../Util/Animation';
import "../assets/css/Home.css"
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faRocket } from '@fortawesome/free-solid-svg-icons';

import h1im from '../assets/images/h1.jpeg'
import h2im from '../assets/images/h2.jpeg'
import h3im from '../assets/images/h3.jpeg'
import h4im from '../assets/images/h4.jpeg'
import pro1img from "../assets/images/slider/pro1.png"
import l1img from "../assets/images/l1.png"
import bullimg from "../assets/images/bull.png"
import sale1img from "../assets/images/resource/sale1.png"
import sale2img from "../assets/images/resource/sale2.png"
import sale3img from "../assets/images/resource/sale3.png"
import p1img from "../assets/images/p1.jpg"
import p2img from "../assets/images/p2.jpg"
import p3img from "../assets/images/p3.jpg"
import p4img from "../assets/images/p4.png"
import p5img from "../assets/images/p5.jpg"
import p6img from "../assets/images/p6.jpg"
import p7img from "../assets/images/p7.jpg"
import lg1img from "../assets/images/lg1.png"
import img1 from "../assets/images/resource/img1.png"
// import himg from "../assets/images/resource/h.png"
import rmpimg from "../assets/images/rmp.jpg"
import tkmsimg from "../assets/images/tkms.jpeg"
import s1img from "../assets/images/s1.png"
import s2img from "../assets/images/s2.png"
import s3img from "../assets/images/s3.png"
import s4img from "../assets/images/s4.png"
import s5img from "../assets/images/s5.png"
import s6img from "../assets/images/s6.png"
import d1img from "../assets/images/d1.jpeg"
import d2img from "../assets/images/d2.jpeg"
import d3img from "../assets/images/d3.jpeg"
import d4img from "../assets/images/d4.jpeg"
import d5img from "../assets/images/d5.jpeg"
import d6img from "../assets/images/d6.jpeg"
import d7img from "../assets/images/d7.jpeg"
import walletimg from "../assets/images/resource/wallet.gif"
import wall1image from "../assets/images/resource/wall1.png"
import wall2image from "../assets/images/resource/wall2.png"
import wall3image from "../assets/images/resource/wall3.png"
import n1img from "../assets/images/n1.svg"
import n2img from "../assets/images/n2.svg"
import n3img from "../assets/images/n3.svg"
import n4img from "../assets/images/n4.svg"

import adroxwhitepaper from "../assets/images/adroxwhitepaper.pdf"



class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        if (!window.pageloaded) {
            window.pageloaded = true;
            window.particleground(document.getElementById('particle-1'), {
                dotColor: '#5e30a1',
                lineColor: '#3c1e32',
            });
            new window.Swiper(".banner-item-slider", {
                effect: "cube",
                grabCursor: true,
                cubeEffect: {
                    shadow: false,
                    slideShadows: false,
                    shadowOffset: 20,
                    shadowScale: 0.94,
                },
                pagination: {
                    el: ".swiper-pagination",
                },
                loop: true,
                autoplay: true,
            });
        }

    }
    render() {
        return (<>
            <div id='particle-1' className="particles particle-1"  >
                <div className="webcoderskull">
                    <section className="banner-section light-version">
                        <Container>
                            <div className="banner-wrapper">
                                <Row className="align-items-center g-5">
                                    <Col lg={6}>
                                        <Animation className="banner-content" variants={fadeInLeft} delay={600}>
                                            <h1><strong>Your last chance to join the journey with us. </strong><br /> <span>Presale</span> is <span>live Now</span></h1>
                                            <p>Join the ADROX crypto presale, engage with our projects, and see your contribution grow to new summits.</p>
                                            <div className="banner-button">
                                                <Link to={'/whitepaper'} > <span> <i> <FontAwesomeIcon icon={faRocket} /> </i>Explore Now</span> </Link>
                                                <Link to={'/buy'} className="default-btn"> <span> Buy <i> <FontAwesomeIcon icon={faArrowRight} /> </i></span> </Link>
                                            </div>
                                        </Animation>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="nft-slider-wrapper wow fadeInUp" data-wow-delay=".7s">
                                            <div className="banner-item-slider">
                                                <div className="swiper-wrapper">
                                                    <div className="swiper-slide">
                                                        <div className="nft-item light-version">
                                                            <div className="nft-inner">
                                                                <div className="nft-item-bottom">
                                                                    <div className="nft-thumb">
                                                                        <img src={h1im} alt="nft-img" />
                                                                    </div>
                                                                    <div className="nft-content">
                                                                        <h4><Link to={'/buy'}>1 ADX = 0.05$</Link> </h4>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="swiper-slide">
                                                        <div className="nft-item light-version">
                                                            <div className="nft-inner">
                                                                <div className="nft-item-bottom">
                                                                    <div className="nft-thumb">
                                                                        <img src={h2im} alt="nft-img" />
                                                                    </div>
                                                                    <div className="nft-content">
                                                                        <h4><Link to={'/buy'}>1 ADX = 0.05$</Link> </h4>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="swiper-slide">
                                                        <div className="nft-item light-version">
                                                            <div className="nft-inner">

                                                                <div className="nft-item-bottom">
                                                                    <div className="nft-thumb">
                                                                        <img src={h3im} alt="nft-img" />
                                                                    </div>
                                                                    <div className="nft-content">
                                                                        <h4><Link to={'/buy'}>1 ADX = 0.05$</Link> </h4>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="swiper-slide">
                                                        <div className="nft-item light-version">
                                                            <div className="nft-inner">

                                                                <div className="nft-item-bottom">
                                                                    <div className="nft-thumb">
                                                                        <img src={h4im}
                                                                            alt="nft-img" />
                                                                    </div>
                                                                    <div className="nft-content">
                                                                        <h4><Link to={'/buy'}>1 ADX = 0.05$</Link> </h4>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </section>
                </div>
            </div>

            <div className="auctions-area style-four pt-80 pb-70" id="auction">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="dreamit-section-title section2 text-center wow fadeInLeft" data-wow-delay=".5s">
                                <h4>Presale</h4>
                                <h1>$1.6 M Raised<span> So Far.</span></h1>
                                <div className="nft-shape">
                                    <img src={pro1img} alt="" />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row">

                    </div>
                </div>
            </div>

            <div className="feature-nftpro-area style-two" id="nftpro">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="feature-nftpro-thumb-box wow fadeInDown" data-wow-delay=".4s">

                                <div className="feature-thumb-innner3 wood">
                                    <img src={l1img} alt="" />
                                </div>
                                <div className="feature-main-thumb wow fadeInRight bl" data-wow-delay=".5s">
                                    <img src={bullimg} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="dreamit-section-title version3 up1 wow fadeInRight" data-wow-delay=".6s">
                                <h4>Welcome to ADROX Market</h4>
                                <h1 className="adrs">
                                    <span>A</span>utomated <span>D</span>ecentralized <span>R</span>esourse <span>O</span>ptimization e<span>X</span>change Wallet</h1>
                                <div className="nft-shape">
                                    <img src={pro1img} alt="" />
                                </div>
                                <p>ADROX the Frontier of Future Finance, where the future of cryptocurrency meets innovative technology ADROX isn't just another digital currency; it's a comprehensive ecosystem designed to revolutionize how we engage with blockchain technology.
                                    Founded with a
                                    vision to revolutionize financial ecosystems through advanced technological
                                    solutions, Adrox is committed to leveraging blockchain’s transformative potential to
                                    create secure, efficient, and inclusive digital economies.
                                </p>
                                <div className="em_bar_bg"></div>
                            </div>

                        </div>
                    </div><br /><br />
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="sell-collect-box wow fadeInUp" data-wow-delay=".4s">
                                <div className="sell-collect-box-inner">
                                    <div className="sell-collect-icon">
                                        <div className="icon-thumb">
                                            <img src={sale1img} alt="" />
                                        </div>
                                    </div>
                                    <div className="sell-number">
                                        <h5><span>i++</span></h5>
                                    </div>
                                    <div className="sell-collect-content">
                                        <div className="sell-title">
                                            <h1>Automation and <br />Optimization</h1>
                                            <p>ADROX automates the management and exchange of digital assets using smart contracts and advanced algorithms optimizing resource allocation<br /> and reducing the need for manual intervention.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="sell-collect-box wow fadeInDown" data-wow-delay=".5s">
                                <div className="sell-collect-box-inner">
                                    <div className="sell-collect-icon">
                                        <div className="icon-thumb">
                                            <img src={sale2img} alt="" />
                                        </div>
                                    </div>
                                    <div className="sell-number">
                                        <h5><span>j++</span></h5>
                                    </div>
                                    <div className="sell-collect-content">
                                        <div className="sell-title">
                                            <h1>Decentralization <br />System</h1>
                                            <p>Operating on blockchain technology, ADROX ensures security, transparency, and trust by enabling peer-to-peer transactions without intermediaries, thereby minimizing transaction costs and enhancing user control.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="sell-collect-box wow fadeInUp" data-wow-delay=".4s">
                                <div className="sell-collect-box-inner">
                                    <div className="sell-collect-icon">
                                        <div className="icon-thumb">
                                            <img src={sale3img} alt="" />
                                        </div>
                                    </div>
                                    <div className="sell-number">
                                        <h5> <span>k++</span></h5>
                                    </div>
                                    <div className="sell-collect-content">
                                        <div className="sell-title">
                                            <h1>Comprehensive Asset Management</h1>
                                            <p>ADROX provides a secure and user-friendly wallet for storing, managing, and exchanging a wide range of digital assets, supporting cross-chain compatibility and various DeFi activities like lending, borrowing, anc yield farming.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="auctions-area prj style-four pt-80 pb-70" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Animation className="dreamit-section-title section2 text-center" variants={fadeInLeft} delay={500}  >
                                <h4>Projects we are associated with</h4>
                                <h1>The Future is <span>With Us</span></h1>
                                <div className="nft-shape">
                                    <img src={pro1img} alt="" />
                                </div>
                                <p>Adrox envisions a future where decentralized technologies enable seamless,
                                    borderless transactions, empower communities, and drive economic prosperity
                                    worldwide. Through continuous innovation, strategic partnerships, and community-
                                    centric development, we strive to pioneer the next generation of blockchain
                                    solutions that empower individuals and businesses alike.</p>
                            </Animation>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <Animation className="dreamit-auction-box " delay={500} variants={fadeInLeft}>
                                <div className="auction-box-inner">
                                    <div className="dreamit-auction-main-thumb">
                                        <img src={p1img} alt="" />

                                    </div>
                                    <div className="auction-content">
                                        <div className="auction-head">


                                        </div>
                                        <div className="em-auction-title">
                                            <h2><Link to={'/buy'}>TOKENISATION OF REAL
                                                WORLD ASSETS (RWA)</Link></h2>
                                        </div>
                                        <div className="em-auction-right1">
                                            <p>Releasing: Nov 2025</p>
                                        </div>


                                    </div>
                                </div>
                            </Animation>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <Animation className="dreamit-auction-box" delay={500} variants={fadeInUp}>
                                <div className="auction-box-inner">
                                    <div className="dreamit-auction-main-thumb">
                                        <img src={p2img} alt="" />

                                    </div>
                                    <div className="auction-content">
                                        <div className="auction-head">


                                        </div>
                                        <div className="em-auction-title">
                                            <h2><Link to={'/buy'}>MAINNET BETA OF XYPHOUS
                                                BLOCKCHAIN NETWORK (XBN)</Link></h2>
                                        </div>
                                        <div className="em-auction-right1">
                                            <p>Releasing: Sept 2025</p>
                                        </div>


                                    </div>
                                </div>
                            </Animation>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <Animation className="dreamit-auction-box wow fadeInUp animated" delay={500} variants={fadeInUp}>
                                <div className="auction-box-inner">
                                    <div className="dreamit-auction-main-thumb">
                                        <img src={p3img} alt="" />

                                    </div>
                                    <div className="auction-content">
                                        <div className="auction-head">


                                        </div>
                                        <div className="em-auction-title">
                                            <h2><Link to={'/buy'} >PROJECT PILOT AI- AD
                                                TECHNOLOGY (A)2 ID - COMPLETELY </Link></h2>
                                        </div>
                                        <div className="em-auction-right1">
                                            <p>Releasing: March 2026</p>
                                        </div>

                                    </div>
                                </div>
                            </Animation>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <Animation className="dreamit-auction-box " delay={500} variants={fadeInUp}>
                                <div className="auction-box-inner">
                                    <div className="dreamit-auction-main-thumb">
                                        <img src={p4img} alt="" />

                                    </div>
                                    <div className="auction-content">
                                        <div className="auction-head">


                                        </div>
                                        <div className="em-auction-title">
                                            <h2><Link to={'/buy'} >LAUNCH OF LENDING
                                                PROTOCOL <br />POOL (LPL) </Link></h2>
                                        </div>
                                        <div className="em-auction-right1">
                                            <p>Releasing: April 2025</p>
                                        </div>


                                    </div>
                                </div>
                            </Animation>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <Animation className="dreamit-auction-box " delay={500} variants={fadeInUp}>
                                <div className="auction-box-inner">
                                    <div className="dreamit-auction-main-thumb">
                                        <img src={p5img} alt="" />

                                    </div>
                                    <div className="auction-content">
                                        <div className="auction-head">


                                        </div>
                                        <div className="em-auction-title">
                                            <h2><Link to={"/buy"} >SHATTERPROOF PROGRAMMING LANGUAGE
                                                INTRODUCTION </Link></h2>
                                        </div>
                                        <div className="em-auction-right1">
                                            <p>Releasing: Feb 2025</p>
                                        </div>


                                    </div>
                                </div>
                            </Animation>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <Animation className="dreamit-auction-box " delay={500} variants={fadeInUp}>
                                <div className="auction-box-inner">
                                    <div className="dreamit-auction-main-thumb">
                                        <img src={p6img} alt="" />

                                    </div>
                                    <div className="auction-content">
                                        <div className="auction-head">


                                        </div>
                                        <div className="em-auction-title">
                                            <h2><Link to={'/buy'}>LAUNCH ADROX COIN
                                                CENTRALIZED EXCHANGE</Link></h2>
                                        </div>
                                        <div className="em-auction-right1">
                                            <p>Releasing: May 2025</p>
                                        </div>

                                    </div>
                                </div>
                            </Animation>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <Animation className="dreamit-auction-box " delay={500} variants={fadeInUp}   >
                                <div className="auction-box-inner">

                                </div>
                            </Animation>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <Animation className="dreamit-auction-box "  delay={500} variants={fadeInUp}  data-wow-delay=".5s" >
                                <div className="auction-box-inner">
                                    <div className="dreamit-auction-main-thumb">
                                        <img src={p7img} alt="PROJECT HUMANOID"/>

                                    </div>
                                    <div className="auction-content">
                                        <div className="auction-head">


                                        </div>
                                        <div className="em-auction-title">
                                            <h2><a href="item-details.html">PROJECT HUMANOID ROBOTICS</a></h2>
                                        </div>
                                        <div className="em-auction-right1">
                                            <p>Releasing: March 2026</p>
                                        </div>


                                    </div>
                                </div>
                            </Animation>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <Animation className="dreamit-auction-box "  delay={500} variants={fadeInUp} >
                                <div className="auction-box-inner">

                                </div>
                            </Animation>
                        </div>



                    </div>
                </div>
            </div>


            <div id='particle-2' className="particles particle-2" >

                <div className="webcoderskull">
                    <section className="banner-section light-version">
                        <div className="container">
                            <div className="banner-wrapper">
                                <div className="row align-items-center g-5">
                                    <div className="col-lg-3">
                                        <div className="banner-content wow fadeInLeft" data-wow-delay=".6s">

                                            <div className="banner-button">

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="nft-slider-wrapper wow fadeInUp" data-wow-delay=".7s">
                                            <div className="banner-item-slider">
                                                <div className="swiper-wrapper">
                                                    <div className="swiper-slide">
                                                        <div className="nft-item light-version">
                                                            <div className="nft-inner">

                                                                <div className="nft-item-bottom sect">
                                                                    <div className="nft-thumb">
                                                                        <img src={lg1img} alt="nft-img" />
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="swiper-slide">
                                                        <div className="nft-item light-version">
                                                            <div className="nft-inner">

                                                                <div className="nft-item-bottom sect">
                                                                    <div className="nft-thumb">
                                                                        <img src={lg1img} alt="nft-img" />
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="swiper-slide">
                                                        <div className="nft-item light-version">
                                                            <div className="nft-inner">

                                                                <div className="nft-item-bottom sect">
                                                                    <div className="nft-thumb">
                                                                        <img src={lg1img} alt="nft-img" />
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="swiper-slide">
                                                        <div className="nft-item light-version">
                                                            <div className="nft-inner">

                                                                <div className="nft-item-bottom sect">
                                                                    <div className="nft-thumb">
                                                                        <img src={lg1img}
                                                                            alt="nft-img" />
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="banner-content wow fadeInLeft" data-wow-delay=".6s">

                                            <div className="banner-button">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div className="sell-collect-section pb-90">
                <div className="container">
                    <br />	<br /><br />	<br />
                    <Animation className="row sell-bg " delay={400} variants={fadeInLeft} >
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <Animation className="title sell " delay={400} variants={fadeInDown} >

                                <h1>Whitepaper</h1>
                                <p className="nft-text">The ADROX whitepaper is a comprehensive document that outlines the technical foundation features, and vision of the Automated Decentralized Resource Optimization Exchange Wallet. It details how ADROX leverages blockchain technology smart contracts and advanced algorithms to automate and optimize digital asset management and exchanges.</p>
                                <div className="sell-button">
                                    <a href={adroxwhitepaper} target="blank">Read WhitePaper <i className="flaticon-right-arrow"></i></a>
                                </div>
                            </Animation>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <Animation className="sell-collect-thumb" delay={400} variants={fadeInUp}>
                                <img src={img1} alt="nft-img" />
                            </Animation>
                        </div>
                    </Animation>
                </div>
            </div>

            <div className="auctions-area pt-80 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <Animation className="dreamit-section-title auction" variants={fadeInLeft} delay={500}>
                                <h4>Youtube</h4>
                                <h1>Live reviews</h1>
                            </Animation>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Animation className="dreamit-auction-box " variants={fadeInUp} delay={500}>
                                <div className="auction-box-inner">
                                    <div className="auction-head">
                                        <div className="auction-head-images">
                                            {/* <img src={himg} alt="" /> */}
                                            <div className="auction-headi-title">
                                                <h6>@PurpledudeNFT </h6>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="dreamit-auction-main-thumb">
                                        <iframe width="560" height="315" src="https://www.youtube.com/embed/oM-Sij90qiI?si=uaNHZ2TJblUMkZMN" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                    </div>

                                </div>
                            </Animation>
                        </div>
                        <div className="col-md-6">
                            <Animation className="dreamit-auction-box" variants={fadeInUp} delay={600}>
                                <div className="auction-box-inner">
                                    <div className="auction-head">
                                        <div className="auction-head-images">
                                            {/* <img src={himg} alt="" /> */}
                                            <div className="auction-headi-title">
                                                <h6>@Fabri Crypto
                                                </h6>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="dreamit-auction-main-thumb">
                                        <iframe width="560" height="315" src="https://www.youtube.com/embed/NiMnmYbvAA8?si=V6GoLT7Y0y1FBkNY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        <div className="meta-icon">
                                            <Link to={'/buy'}><i className="fas fa-heart"></i> <span>20.9k</span></Link>
                                        </div>
                                    </div>

                                </div>
                            </Animation>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sell-collect-section new pb-90">
                <div className="container">
                    <br />	<br /><br />	<br />
                    <Animation className="row sell " variants={fadeInLeft} delay={400}>
                        <div className=" col-md-12 ">
                            <img src={rmpimg} alt="" />
                        </div>

                    </Animation>
                </div>
            </div>

            <div className="auctions-area style-four pt-80 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Animation className="dreamit-section-title section2 text-center " variants={fadeInLeft} delay={500}>

                                <h1>ADROX<span> Tokenomics</span></h1>
                                <div className="nft-shape">
                                    <img src={pro1img} alt="" />
                                </div>
                                <p>Check our allocations</p>
                            </Animation>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <Animation className="dreamit-auction-box " variants={fadeInUp} delay={500} >
                                <div className="auction-box-inner">

                                </div>
                            </Animation>
                        </div>
                        <div className="col-lg-6 col-md-6 ">
                            <Animation className="dreamit-auction" variants={fadeInDown} delay={600} >
                                <div className="auction-box-inner">
                                    <div className="dreamit-auction-main-thumb">
                                        <img src={tkmsimg} alt="" />
                                    </div>

                                </div>
                            </Animation>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <Animation className="dreamit-auction-box " variants={fadeInUp} delay={800}>
                                <div className="auction-box-inner">


                                </div>
                            </Animation>
                        </div>
                    </div>
                </div><br /><br />
            </div>

            <div className="guidline-area pt-85 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                            <div className="dreamit-section-title version2 text-center wow fadeInRight power" data-wow-delay=".4s">

                                <h1>Powered By</h1>
                                <div className="em_bar_bg"></div>

                            </div>
                        </div>
                    </div><br /><br />
                    <div className="row">
                        <div className="col-lg-2 col-md-6 col-sm-12">
                            <div className="dreamit-guidline-box wow fadeInLeft" data-wow-delay=".5s">
                                <div className="guidline-inner-box">
                                    <div className="guidline-thumb pr">
                                        <img src={s1img} alt="" />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12">
                            <div className="dreamit-guidline-box wow fadeInDown" data-wow-delay=".6s">
                                <div className="guidline-inner-box">
                                    <div className="guidline-thumb pr">
                                        <img src={s2img} alt="" />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12">
                            <div className="dreamit-guidline-box wow fadeInRight" data-wow-delay=".7s">
                                <div className="guidline-inner-box">
                                    <div className="guidline-thumb pr">
                                        <img src={s3img} alt="" />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12">
                            <div className="dreamit-guidline-box wow fadeInRight" data-wow-delay=".7s">
                                <div className="guidline-inner-box">
                                    <div className="guidline-thum pr">
                                        <img src={s4img} alt="" />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12">
                            <div className="dreamit-guidline-box wow fadeInRight" data-wow-delay=".7s">
                                <div className="guidline-inner-box">
                                    <div className="guidline-thum pr">
                                        <img src={s5img} alt="" />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12">
                            <div className="dreamit-guidline-box wow fadeInRight" data-wow-delay=".7s">
                                <div className="guidline-inner-box">
                                    <div className="guidline-thum pr">
                                        <img src={s6img} alt="" />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="auctions-area cnd style-four pt-80 pb-70" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="dreamit-section-title section2 text-center wow fadeInLeft" data-wow-delay=".5s">
                                <h4>Book Now</h4>
                                <h1>1 ADX =<span> 0.05 $</span></h1>
                                <div className="nft-shape">
                                    <img src={pro1img} alt="" />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row">

                    </div>
                </div>
            </div>



            <div className="feature-nftpro-area style-two" id="nftpro">
                <div className="container">

                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="dreamit-section-title pb-5">
                                <h4>Team Adrox</h4>
                                <h1>Our Directors</h1>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <Animation className="dreamit-explore-box wow fadeInUp animated" delay={500} variants={fadeInUp} >
                                <div className="explore-box-inner dir">
                                    <div className="dreamit-explore-main-thumb">
                                        <Link><img src={d6img} alt="" /></Link>
                                        <div className="meta-explore-icon">
                                            <Link><i className="fas fa-heart"></i> <span>5.1k</span></Link>
                                        </div>
                                    </div>
                                    <div className="eplore-item-content">
                                        <div className="em-explore-title">
                                            <h2><Link>Aaron</Link></h2>
                                        </div>

                                    </div>
                                    <div className="search-box-btn search-box-outer upper3">
                                        <Link> <i className="fas fa-user"></i> Director</Link>
                                    </div>
                                </div>
                            </Animation>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <Animation className="dreamit-explore-box " delay={500} variants={fadeInUp} >
                                <div className="explore-box-inner dir">
                                    <div className="dreamit-explore-main-thumb">
                                        <Link><img src={d1img} alt="" /></Link>
                                        <div className="meta-explore-icon">
                                            <Link><i className="fas fa-heart"></i> <span>4.3k</span></Link>
                                        </div>
                                    </div>
                                    <div className="eplore-item-content">
                                        <div className="em-explore-title">
                                            <h2><Link>Nathan</Link></h2>
                                        </div>

                                    </div>
                                    <div className="search-box-btn search-box-outer upper3">
                                        <Link> <i className="fas fa-user"></i> Director</Link>
                                    </div>
                                </div>
                            </Animation>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <Animation className="dreamit-explore-box" delay={500} variants={fadeInUp} >
                                <div className="explore-box-inner dir">
                                    <div className="dreamit-explore-main-thumb">
                                        <Link><img src={d3img} alt="" /></Link>
                                        <div className="meta-explore-icon">
                                            <Link><i className="fas fa-heart"></i> <span>4.7k</span></Link>
                                        </div>
                                    </div>
                                    <div className="eplore-item-content">
                                        <div className="em-explore-title">
                                            <h2><Link>Victor</Link></h2>
                                        </div>

                                    </div>
                                    <div className="search-box-btn search-box-outer upper3">
                                        <Link> <i className="fas fa-user"></i> Director</Link>
                                    </div>
                                </div>
                            </Animation>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <Animation className="dreamit-explore-box " delay={500} variants={fadeInUp} >
                                <div className="explore-box-inner dir">
                                    <div className="dreamit-explore-main-thumb">
                                        <Link><img src={d2img} alt="" /></Link>
                                        <div className="meta-explore-icon">
                                            <Link><i className="fas fa-heart"></i> <span>7.1k</span></Link>
                                        </div>
                                    </div>
                                    <div className="eplore-item-content">
                                        <div className="em-explore-title">
                                            <h2><Link>Joanne</Link></h2>
                                        </div>

                                    </div>
                                    <div className="search-box-btn search-box-outer upper3">
                                        <Link> <i className="fas fa-user"></i> Director</Link>
                                    </div>
                                </div>
                            </Animation>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <Animation className="dreamit-explore-box " delay={500} variants={fadeInUp} >
                                <div className="explore-box-inner dir">
                                    <div className="dreamit-explore-main-thumb">
                                        <Link><img src={d4img} alt="" /></Link>
                                        <div className="meta-explore-icon">
                                            <Link><i className="fas fa-heart"></i> <span>3.3k</span></Link>
                                        </div>
                                    </div>
                                    <div className="eplore-item-content">
                                        <div className="em-explore-title">
                                            <h2><Link>Miller</Link></h2>
                                        </div>

                                    </div>
                                    <div className="search-box-btn search-box-outer upper3">
                                        <Link> <i className="fas fa-user"></i> CTO</Link>
                                    </div>
                                </div>
                            </Animation>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <Animation className="dreamit-explore-box " delay={500} variants={fadeInUp} >
                                <div className="explore-box-inner dir">
                                    <div className="dreamit-explore-main-thumb">
                                        <Link><img src={d5img} alt="" /></Link>
                                        <div className="meta-explore-icon">
                                            <Link><i className="fas fa-heart"></i> <span>6.9k</span></Link>
                                        </div>
                                    </div>
                                    <div className="eplore-item-content">
                                        <div className="em-explore-title">
                                            <h2><Link  >Amine</Link></h2>
                                        </div>

                                    </div>
                                    <div className="search-box-btn search-box-outer upper3">
                                        <Link> <i className="fas fa-user"></i> COO</Link>
                                    </div>
                                </div>
                            </Animation>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <Animation className="dreamit-explore-box " delay={500} variants={fadeInUp} >
                                <div className="explore-box-inner dir">
                                    <div className="dreamit-explore-main-thumb">
                                        <Link><img src={d7img} alt="" /></Link>
                                        <div className="meta-explore-icon">
                                            <Link><i className="fas fa-heart"></i> <span>5.3k</span></Link>
                                        </div>
                                    </div>
                                    <div className="eplore-item-content">
                                        <div className="em-explore-title">
                                            <h2><Link>Shedrah</Link></h2>
                                        </div>

                                    </div>
                                    <div className="search-box-btn search-box-outer upper3">
                                        <Link> <i className="fas fa-user"></i> CFO</Link>
                                    </div>
                                </div>
                            </Animation>
                        </div>
                        <Animation className="item-button text-center" delay={1400} variants={fadeInUp} >
                            <Link>Load More <i className="fas fa-sync-alt"></i></Link>
                        </Animation>
                    </div>
                </div>
            </div>
            <div className="wallet-section pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="dreamit-wallet-thumb wow fadeInUp" data-wow-delay=".3s">
                                <img src={walletimg} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="row">
                                <div className="dreamit-section-title pb-5">
                                    <h4>How To Buy</h4>
                                    <h1>ADX Coin</h1>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="wallet-box wow fadeInUp" data-wow-delay=".4s">
                                        <div className="wallet-thumb">
                                            <img src={wall1image} alt="" />
                                        </div>
                                        <div className="wallet-title">
                                            <h3>Connect your wallet</h3>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="wallet-box wow fadeInUp" data-wow-delay=".5s">
                                        <div className="wallet-thumb">
                                            <img src={wall2image} alt="" />
                                        </div>
                                        <div className="wallet-title">
                                            <h3>⁠Buy <br />tokens</h3>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="wallet-box wow fadeInUp" data-wow-delay=".6s">
                                        <div className="wallet-thumb">
                                            <img src={wall3image} alt="" />
                                        </div>
                                        <div className="wallet-title">
                                            <h3>Verify and<br /> claim</h3>

                                        </div>
                                    </div>
                                </div>


                                <div className="wallet-button">
                                    <Link to={'/buy'}>Buy Now</Link>
                                </div>
                                <div className="col-lg-12 col-sm-12">
                                    <div className="wallet-content wow fadeInUp" data-wow-delay="1s">
                                        <div className="wallet-icon">

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="guidline-area pt-85 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                            <div className="dreamit-section-title version2 text-center wow fadeInRight" data-wow-delay=".4s">
                                <h4>News Platform</h4>
                                <h1>Press release on Adroxmarket</h1>
                                <div className="em_bar_bg"></div>
                                <p className="about-setcion">Find us across the digital platforms and news, check us out, endure the beat of the ADROXMARKET</p>
                            </div>
                        </div>
                    </div><br /><br />
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="dreamit-guidline-box wow fadeInLeft" data-wow-delay=".5s">
                                <div className="guidline-inner-box">
                                    <div className="guidline-thumb pr">
                                        <img src={n1img} alt="" />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="dreamit-guidline-box wow fadeInDown" data-wow-delay=".6s">
                                <div className="guidline-inner-box">
                                    <div className="guidline-thumb pr">
                                        <img src={n2img} alt="" />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="dreamit-guidline-box wow fadeInRight" data-wow-delay=".7s">
                                <div className="guidline-inner-box">
                                    <div className="guidline-thumb pr">
                                        <img src={n3img} alt="" />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="dreamit-guidline-box wow fadeInRight" data-wow-delay=".7s">
                                <div className="guidline-inner-box">
                                    <div className="guidline-thum pr">
                                        <img src={n4img} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>);
    }
}
export default Home;