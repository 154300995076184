import React, { Component } from 'react';

import l1 from "../assets/images/l1.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Form,Col, Container,  Row } from 'react-bootstrap';
import { faBell, faSearch } from '@fortawesome/free-solid-svg-icons';
import Order from '../service/Order';

class StickyHeader extends Component {
    constructor(props) {
      super(props);
      this.state = { 
        orderstatus:'pending',
        formData:{
            s:null
        }
      };
    }
    componentDidMount() {
        this.intervalId = setInterval(this.handleInterval, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }
    handleInterval = () => {
        const orderstatus=Order.Account.status||"pending";
        this.setState({orderstatus})
    } 
    handleSubmit=async (e)=>{
        e.preventDefault();
    }
    handleChange=async (e)=>{
        e.preventDefault();
    }
    render() {
        return <div className="nftpro_nav_manu upper">
            <Container>
                <Row className="align-items-center">
                    <Col lg={2}>
                        <div className="logo">
                            <Link to={'/'} className="logo_img" title="nftpro">
                                <img src={l1} alt="logo" />
                            </Link>

                            <Link to={'/'} className="main_sticky" title="nftpro">
                                <img src={l1} alt="astute" />
                            </Link>
                        </div>
                    </Col>
                    <Col lg={10}>
                        <nav className="nftpro_menu">
                            <ul className="nav_scroll">
                                <li > <Link to={'/'}><span>Home</span> </Link> </li>
                                <li > <Link to={'/whitepaper'}><span>Company WhitePaper</span> </Link> </li>
                                <li > <Link to={'/roadmap'}><span>Our Road map</span> </Link> </li>                                    
                                {this.state.orderstatus==="active"?(<li>
                                        <Link to={'/wallet'}><span> My Wallet </span></Link>
                                </li>):(<li>
                                        <Link to={'/wallet'}><span> Login </span></Link>
                                </li>)}
                            </ul>
                            <div className="header_widget_search">
                                <div className="search">
                                    <Form noValidate onSubmit={this.handleSubmit} > 
                                        <input type={"text"} name={"s"} value={this.state.formData?.s||''} placeholder={"Search Here"} title={"Search for:"} onChange={this.handleChange} />
                                        <i><FontAwesomeIcon icon={faSearch } /></i>
                                        <div className="bell-button"><i><FontAwesomeIcon icon={faBell } /></i> <span>6</span></div>
                                    </Form> 
                                </div>
                            </div>
                            <div className="header-button">
                                <Link to={'/buy'}>Buy Now </Link>
                            </div>
                        </nav>
                    </Col>
                </Row>
            </Container>
        </div>
    }
}

export default StickyHeader;