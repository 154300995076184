import React, { Component } from "react";

import l1 from "../assets/images/l1.svg";
import { Link } from "react-router-dom";
import Order from "../service/Order";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

class MobileMenu extends Component {
    constructor(props) {
      super(props);
      this.state = { 
        orderstatus:"pending",
        navstyle:{
            display:"none"
        },
        showmenu:false,
      };
    }
    componentDidMount() {
        this.intervalId = setInterval(this.handleInterval, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }
    handleInterval = () => {
        const orderstatus=Order.Account.status||"pending";
        this.setState({orderstatus})
    } 
    handleNavMenu=()=>{
        const showmenu = !this.state.showmenu;
        const navstyle = {
            ...this.state.navstyle,
            display:(showmenu?"block":"none")
        }
        this.setState({showmenu,navstyle})
    }
    render() {
        return <div className="mobile-menu-area d-sm-block d-md-block d-lg-none ">
            <div className="mobile-menu mean-container">
                <div className="mean-bar">
                    <Link to={'/'} className="logo_img" title="nftpro">
                        <img src={l1} alt="logo" />
                    </Link>
                    <Link onClick={this.handleNavMenu} className="m-2 menu-bar" title="nftpro">
                        <FontAwesomeIcon icon={faBars} />
                    </Link>
                    <div className="header-button hd">
                        <Link to={'/buy'}>Buy Now</Link>
                    </div>
                </div>
                <nav className="nftpro_menu" style={this.state.navstyle}>
                    <ul className="nav_scroll">
                        <li > <Link to={'/'}><span>Home</span> </Link> </li>
                        <li > <Link to={'/whitepaper'}><span>Company WhitePaper</span> </Link> </li>
                        <li > <Link to={'/roadmap'}><span>Our Road map</span> </Link> </li>
                        {this.state.orderstatus==="active"?(<li > 
                            <Link to={'/wallet'}> My Wallet </Link> 
                        </li>):(<li > 
                            <Link to={'/wallet'}> Login </Link> 
                        </li>)}
                    </ul>
                </nav>
            </div>
        </div>
    }
}

export default MobileMenu;