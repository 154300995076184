import { v4 as uuidv4 } from "uuid";
import { request } from "../Util/request";

class Order {
    static get LoginedUser(){
        return JSON.parse(localStorage.getItem("_adx_logined_user"))||{};
    }
    static set LoginedUser(loginSession){
        localStorage.setItem("_adx_logined_user",JSON.stringify(loginSession||{}))
    }
    static get Account(){
        return JSON.parse(localStorage.getItem("_adx_user_account"))||{};
    }
    static set Account(account){
        localStorage.setItem("_adx_user_account",JSON.stringify(account||{}))
    }
    static async generateOrderID(){  
        const id = uuidv4();
        const orderid =await request.get('/api/').then((response)=>{                
            if(response.status===200){
                return response.data?.orderid??id;
            }else{                  
                return id;
            }
        }).catch(()=>{ 
            return id;
        }); 
        return orderid;
    }
    static async getOrderById(orderId){
        const orders =await request.post('/api/',{
            method:'order-by-id',
            id:orderId
        }).then((response)=>{      
            let result = {}
            if(response.status>=200&&response.status<=204){
                result= response.data?.data??{};
            }
            return result;
        }).catch(()=>{ 
            return {};
        }); 
        return orders;
    }
    static async getOrder(){
        const orders =await request.post('/api/',{
            method:'order-list',
            account: Order.Account
        }).then((response)=>{      
            let result = []
            if(response.status>=200&&response.status<=204){
                result= response.data?.data??[];
            }
            return result;
        }).catch(()=>{ 
            return [];
        }); 
        return orders;
    }
    static async createOrder(data){
        const order =await request.post('/api/',{
            method:'order',
            data:data
        }).then((response)=>{      
            let result = {}          
            if(response.status>=200&&response.status<=204){
                result= response.data?.data??{};
            }
            return result;
        }).catch(()=>{ 
            return {};
        }); 
        return order;
    }
    static async updateOrder(data,orderid){

        const order =await request.post('/api/',{
            method:'update-order',
            data:data,
            id:orderid
        }).then((response)=>{      
            let result = {}          
            if(response.status>=200&&response.status<=204){
                result= response.data?.data??{};
            }
            return result;
        }).catch(()=>{ 
            return {};
        }); 
        return order;
    }    
    static async loginAccount(data){
        const auth =await request.post('/api/',{
            method:'login',
            data:data
        }).then((response)=>{      
            let result = {}
            if(response.status>=200&&response.status<=204){
                result= response.data?.data??{};
            }
            return result;
        }).catch(()=>{ 
            return {};
        }); 
        return auth;
    }
    static async registerAccount(data){
        const auth =await request.post('/api/',{
            method:'register',
            data:data
        }).then((response)=>{      
            let result = {}
            if(response.status>=200&&response.status<=204){
                result= response.data?.data??{};
            }
            return result;
        }).catch(()=>{ 
            return {};
        }); 
        return auth;
    }
}
export default Order;